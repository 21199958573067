/* .App {
  text-align: center;
} */

@font-face {
  font-family: "CamingoCode";
  src: url("../fonts/CamingoCode/CamingoCode-Regular.ttf");
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Regular.ttf");
}

/* * {
  font-family: "camingoCode";
} */

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

.ant-card {
  transition: transform 0.2s; /* Animation */
  max-width: 400px;
}

.ant-card:hover {
  transform: scale(
    1.1
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  box-shadow: 1px 8px 20px grey;
}

.ant-card .ant-card-meta-title {
  white-space: unset;
}

.wrapper-form {
  margin: 0px 450px;
}

.logo-name {
  color: white;
  margin-top: -10px;
}

/* 
@media only screen and (max-width: 550px) {
  .ant-card .ant-card-meta-title{
    white-space: unset;
  }
} */

.upload-img {
  padding: 20px 0;
}

.logo-img {
  margin-top: 10px;
}

.ant-menu-item {
  white-space: unset !important;
}

.login-container {
  height: 100vh;
}

.background-cover {
  max-width: 100%;
  height: 100vh;
  object-fit: cover;
}

.auth-form {
  margin: 15%;
}

.auth-login {
  width: 70%;
  margin: auto;
  display: block;
  min-height: 150px;
}

.auth-wrapper-form {
  margin: 0 35%;
}

.terms-condition {
  margin: 5%;
}

.terms-logo {
  width: 20%;
}

.loading {
  margin: 15%;
}

.progress-course-lecture {
  margin: 10px 25px;
  width: 90%;
  color: white;
}

.progress-course-lecture .ant-progress-text {
  color: white !important;
}
.group-lecture-group {
  margin: 50px 0;
}

#listGroup {
  cursor: pointer;
}

.group-lecture-title {
  padding: 30px;
}

.all-schools {
  margin: 50px 0;
}

.profile-img {
  margin: auto;
  display: block;
  width: auto;
  max-width: 60%;
}

.course-img {
  margin: 10%;
  max-width: 80%;
}
.skeleton-img {
  margin: 10%;
  min-height: 320px;
}

.txt-dark-mode {
  margin-top: 10px;
}
.btn-back {
  color: #ffffff !important;
  border-color: #ffffff !important;
}

.title-badge-menu {
  color: rgba(255, 255, 255, 0.65);
}

.lecture {
  margin-bottom: 50px;
}

.lecture img {
  height: auto !important ;
  max-width: 100% !important;
}

.carousel-education {
  background-color: black;
}

.schools-img {
  width: 100%;
  height: 800px;
  object-fit: cover;
  opacity: 0.5;
}

.center {
  text-align: center;
  margin: 60px 0;
}

.over-education {
  padding: 5%;
}

.education-school {
  display: block;
  margin: auto;
  margin-bottom: 100px;
}

.lecture-style {
  margin-top: 25px;
}
.lecture-style * {
  font-family: "Lato" !important;
  line-height: 1.8 !important;
}

.lecture-style p,
.lecture-style div span,
.lecture-style li,
.lecture-style code,
.lecture-style font,
.lecture-style div {
  font-size: 17px !important;
  /* font-weight: 500 !important; */
}

.lecture-b * :not(code, a, span),
.lecture-b .flag,
.lecture-b p span {
  color: #fff !important;
  background-color: #141414 !important;
}

.lecture-style .code-viewer *,
.lecture-style span {
  font-size: 15px !important;
}

/* .lecture-style div :not(.flag-wrapper, .flag, .direction-r, .direction-l, span, em){
  padding-left: 10px !important;
} */

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 15px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
blockquote {
  border-left: 5px solid #e6e6e6;
  margin-bottom: 25px;
  padding-left: 20px;
}
.lecture-style div span {
  line-break: auto;
}



@media only screen and (max-width: 1500px) {
  .wrapper-form {
    margin: 0px 150px;
  }
}

@media only screen and (max-width: 1200px) {
  .btn-title {
    display: none;
  }
  .schools-img {
    height: 400px;
  }
}

@media only screen and (max-width: 1000px) {
  .wrapper-form {
    margin: 0px 50px;
  }
}

@media only screen and (max-width: 700px) {
  .auth-login {
    width: 100%;
    margin: auto;
    display: block;
  }
  .wrapper-form {
    margin: 100px 0px;
  }
  .auth-wrapper-form {
    margin: 0 10%;
    height: 100vh;
  }
  .auth-wrapper-form h2 {
    font-size: larger;
    padding-bottom: 50px;
  }
  .student-info {
    display: none;
  }
  .profile-img {
    margin: 10px;
    max-width: 185px;
  }
  .ant-picker-content {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 500px) {
  .over-education img {
    width: 100% !important ;
  }
}
